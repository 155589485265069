'use client';

import { useEffect } from 'react';

import { setCookie } from 'cookies-next';
import { useRouter, useSearchParams } from 'next/navigation';

import { navigations } from '@/app/constants';

export default function Page() {
  const router = useRouter();
  const searchParams = useSearchParams();
  
  useEffect(()=> {
    const filterParams = Object.fromEntries(searchParams);
    if (filterParams.source) {
      router.push(`${navigations.AI_ANAYTICS}?source=${filterParams.source}`);
    } else {
      router.push(navigations.AI_ANAYTICS);
    }
  },[router]);

  useEffect(() => {
    const filterParams = Object.fromEntries(searchParams);
    if (filterParams.source) {
      setCookie('source', filterParams.source);
    }
  }, [searchParams]);

  return <section></section>;
}
