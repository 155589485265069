export enum CHAIN {
  BITCOIN = 'BITCOIN',
  COSMOS = 'COSMOS',
}

export interface IAIQuestion {
  chain: CHAIN;
  question: string;
}

export const AI_QUESTIONS: IAIQuestion[] = [
  {
    chain: CHAIN.BITCOIN,
    question: 'Which transaction has the highest fee in USD?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'What were the daily average transaction fees in last 10 days for each day?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'How many transactions happened last Monday?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'What is the total transaction amount in ATOM first day of this month?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'What is the total of all transaction fees in USD this month?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me transactions greater than one million USD.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'What was the average ATOM/USD rate yesterday?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'How many transactions were sent from cosmos17vg7d0hcapc9v9enp3yaz22a0mssfvnvqn6cfy?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'What is the total number of blocks recorded?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'How many transactions were received by cosmos17vg7d0hcapc9v9enp3yaz22a0mssfvnvqn6cfy?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'What is the total transaction amount in USD for all last week\'s transactions?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Which date had the highest number of transactions?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'What was the average transaction amount in ATOM first week this year?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'How many transactions were made first week of this month?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me 5 highest transaction fees in ATOM.',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me 10 transactions with the highest fee in USD.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'What is the average transaction fee in ATOM for each month this year?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me daily transactions count this month for each day.',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me total daily transaction amount in USD for each day first week this month.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'How many transactions have a zero fee?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me top ten biggest transactions using USD amount.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me average daily ATOM/USD rate for last 30 days for each day.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me 10 biggest transactions sent from cosmos17vg7d0hcapc9v9enp3yaz22a0mssfvnvqn6cfy.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me 10 blocks with the most transactions this year.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me all rewards received by cosmos17vg7d0hcapc9v9enp3yaz22a0mssfvnvqn6cfy.',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me 10 days with the highest number of daily transactions.',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me daily total transactions amount in USD for each day during the first week of this month.',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'Show me daily total transactions count in last 30 days.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'What are the total daily transaction fees in USD collected for rewards in last 10 days for each day?',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me details of the biggest reward.',
  },
  {
    chain: CHAIN.COSMOS,
    question: 'Show me 10 days with the lowest ATOM/USD rate.'
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'How many transactions were sent from 17ASncw7nmUxgeExKDBP9zyqibg3XwndCM?',
  },
  {
    chain: CHAIN.BITCOIN,
    question: 'How many transactions were received by 17ASncw7nmUxgeExKDBP9zyqibg3XwndCM?',
  },
];
